/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 991px ****************************************/

@media screen and (min-width: 992px) {

    /**********************************************************************************************/
    /***************************************** realisations ***************************************/
    /**********************************************************************************************/
    
    .galery-container {
        top: 12vw;
        width: 26.25vw;
    }

    .galery-button {
        top: 9vw;
        width: 24.5vw;
        font-size: 1.8vw;
        padding: 1.575vw 2.187vw 1.575vw 2.187vw;
    }

    .galery__logo-bcra {
        width: 15vw;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 1199px ****************************************/

@media screen and (min-width: 1200px) {

    /**********************************************************************************************/
    /***************************************** realisations ***************************************/
    /**********************************************************************************************/

    .galery-container {
        top: 13vw;
        width: 21.9vw;
    }

    .galery-button {
        top: 10.84vw;
        width: 20.44vw;
        font-size: 1.5vw;
        padding: 1.214vw 1.725vw 1.214vw 1.725vw;
        border: .146vw solid rgb(255, 153, 0);
    }

    .galery__logo-bcra {
        width: 18vw;
    }
}