/**********************************************************************************************/
/****************************************** galerieCard ***************************************/
/**********************************************************************************************/

.galerie-card {
    position: relative;
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    margin-bottom: 10vw
}

.galerie-card__chantier {
    position: relative;
    width: auto;
    height: 68vw;
    margin-bottom: 5.25vw;
    border-radius: 2.66vw;
    overflow: hidden;
}

.galerie-card__chantier__background {
    position: absolute;
    width: 100%;
    height: 85vw;
    background-color: rgba(0, 0, 0, .4);
    transition: background-color .4s;
}

.galerie-card__chantier__background:hover {
    background-color: transparent;
    transition: background-color .4s;
}

.galerie-card__chantier__picture {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    width: auto;
    height: 90vw;
}

.galerie-card__chantier__title {
    position: absolute;
    margin-left: 5.2vw;
    margin-right: 5.2vw;
    font-size: 4.8vw;
    color: white;
    line-height: 143%;
    bottom: 0;
}

/************************************************************************************************************/
/********************************************* media queries ************************************************/
/********************************** New rules if the screen is at more 450px ********************************/
@media screen and (min-width: 451px) {
    .galerie-card__chantier__title {
        font-size: 3vw;
    }
}